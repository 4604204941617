.box {
  border: 1px solid #ccc;
  padding: 20px;
  margin-bottom: 20px;
}

.box-title {
  font-size: 20px;
  margin-bottom: 10px;
}

.box-content {
  font-size: 16px;
  line-height: 1.5;
}

.month {
  font-weight: 600;
  font-size: 23px;
  margin: -167px -113px 77px -270px;
  padding: 10px 16px;
  color: #007cc2;
  text-align: center;
}

.offerPrice {
  font-weight: 600;
  font-size: 45px;
  margin: -147px -35px 75px 75px;
  padding: 10px 16px;
  color: #ffffff;
  text-align: center;
}

.planName {
  font-weight: 600;
  font-size: 17px;
  margin: -87px -100px 77px -255px;
  padding: 10px 16px;
  color: #008dc4;
  text-align: center;
  text-transform: uppercase;
}

.getStartImage {
  margin: -94px -88px 83px 93px;
}

.getStartImageText {
  font-weight: 600;
  font-size: 17px;
  color: #007cc2;
  margin: -120px -106px 83px 93px;
}

.rupee {
  font-weight: 700;
  font-size: 25px;
  margin: -115px -296px 70px -271px;
  padding: 10px 16px;
  color: #ffffff;
  text-align: center;
}

.oldPrice {
  text-decoration: line-through;
  font-weight: 400;
  font-size: 25px;
  margin: -120px -293px 86px -272px;
  padding: 10px 16px;
  color: #ffffff;
  text-align: center;
}

.hover01 figure:hover img {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}

.zoom-effect {
  transform: scale(1);
}

.image-container:hover .zoom-effect {
  transform: scale(1.1);
}
