/* .custom-accordion .accordion-item {
    border: none;
  }
  
  .custom-header {
    background-color: #212529;
    color: yellow;
    border: none;
    padding: 10px;
  }
  
  .custom-body {
    background-color: #212529;
    color: yellow;
    border: none;
    padding: 10px;
  }

  

  .accordion-button:not(.collapsed) {
    background-color: #212529;
    color: white;
    border: none;
  }

  .accordion-item {
    border: 0;
  } */


  .list-group {
    --bs-list-group-color: var(--bs-body-color);
    --bs-list-group-bg: var(--bs-body-bg);
    --bs-list-group-border-color: rgba(62, 59, 59, 0.909);
    border-radius: 20px;
    
  }

  .list-group-item {
    background-color: #212529;
    
  }
/* 
  .accordion-button:not(.collapsed) {
    color: rgb(255, 0, 0);
    background-color: yellow;
    box-shadow: inset 0 calc(-1* var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
   
  color: rgb(47, 35, 80);
} */

  .accordion-item {
    border-width: 10px;
    border-color: #212529;
  }
  .accordion-button {
    border: none;
    background-color: #212529;
    color: white;
  }



  .accordion-button:not(.collapsed)::after {
   
    color: rgb(255, 255, 255);
  }
  .accordion-header {
    margin-bottom: 0;
    margin-top: -10px;
}