.video-container {
    /* position: relative; */
    /* width: 100%;
     */
     /* height: 100%; */
  }
  
  .video-container::after {
    content: "";
    display: block;
    
  }
  
  .video-js {
    position: absolute;
    top: 0;
    left: 0;
    /* width: 100%; */
    /* height: 100%; */
  }
   .vjs-tech{
    height: 100% !important; 
  }

  .video-js .vjs-big-play-button {
    font-size: 3em;
    line-height: 1.5em;
    height: 1.63332em;
    width: 3em;
    display: none;
    position: absolute;
    top: 40%;
    left: 40%;
    padding: 0;
    cursor: pointer;
    opacity: 1;
    border: 0.06666em solid #fff;
    background-color: #2B333F;
    background-color: rgba(43, 51, 63, 0.7);
    border-radius: 0.3em;
    transition: all 0.4s;
} 

.vjs-has-started .vjs-control-bar, .vjs-audio-only-mode .vjs-control-bar {
  display: flex;
  visibility: visible;
  opacity: 1;
  transition: visibility 0.1s, opacity 0.1s;
  /* background-color:  */
  background: linear-gradient(to bottom,transparent, rgba(0, 0, 0, 1));
  /* margin-bottom: 10px; */
  height: 40px;
}
.video-js .vjs-progress-control {
  cursor: pointer;
  flex: auto;
  display: flex;
  align-items: center;
  min-width: 4em;
  touch-action: none;
  margin-left: -15px;
  margin-right: -15px;
  margin-top: -5px;
}



.video-js .vjs-duration, .vjs-no-flex .vjs-duration {
  display: flex;
} 

.video-js .vjs-volume-panel.vjs-volume-panel-horizontal.vjs-hover{
width: auto; 
  /* transition: width 0.1s;  */
}

.vjs-button > .vjs-icon-placeholder {
  display: block;
  margin-top: -11px;
}

.video-js .vjs-volume-panel.vjs-hover .vjs-volume-control, .video-js .vjs-volume-panel.vjs-hover .vjs-mute-control ~ .vjs-volume-control{
  visibility: visible;
  opacity: 1;
  position: relative;
  /* transition: visibility 0.1s, opacity 0.1s, height 0.1s, width 0.1s, left 0s, top 0s; */
  margin-top: -20px;
  margin-left: 23px;
}

.video-js .vjs-volume-bar {
  /* margin: 1.35em 0.45em; */
  margin-top: 13px;
  margin-left: -40px;
  display: none;
}
.video-js .vjs-progress-holder .vjs-play-progress{
  position: absolute;
  display: block;
  height: 100%;
  margin: 0;
  padding: 0;
  width: 0;
  border-radius: 10px;
}

.video-js .vjs-control {
  position: relative;
  text-align: center;
  margin: 0;
  padding: 0;
  /* height: 100%; */
  width: 3em;
  flex: none;
}

.vjs-has-started .vjs-control-bar, .vjs-audio-only-mode .vjs-control-bar {
  display: flex;
  visibility: visible;
  opacity: 1;
  transition: visibility 0.1s, opacity 0.1s;
  background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 1));
  /* margin-bottom: 10px; */
  height: 40px;
  padding-right: 10px;
  padding-left: 0px;
}

.video-js .vjs-slider {
  height: 4px;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
  padding: 0;
  margin: 0 0.45em 0 0.45em;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  background-color: #73859f;
  background-color: rgba(115, 133, 159, 0.5);
}

.video-js .vjs-progress-holder .vjs-load-progress{
  position: absolute;
  display: block;
  height: 100%;
  margin: 0;
  padding: 0;
  width: 0;
  border-radius: 10px;
}

.video-js .vjs-time-control {
  flex: none;
  font-size: 1em;
  line-height: 3em;
  min-width: 2em;
  width: auto;
  /* padding-left: 1em; */
  /* padding-right: 1em; */
}

.video-js .vjs-play-progress:before {
  font-size: 11px;
  position: absolute;
  right: -0.5em;
  top: -0.3333333333em;
  z-index: 1;
}

.vjs-menu-button-popup .vjs-menu {
  display: none;
  position: absolute;
  bottom: 0;
  width: 10em;
  left: -3em;
  height: 0em;
  margin-bottom: 1.5em;
  border-top-color: rgba(43, 51, 63, 0.7);
  z-index: 99999999;
}

.video-js .vjs-progress-control {
  cursor: pointer;
  flex: auto;
  display: flex;
  align-items: center;
  min-width: 4em;
  touch-action: none;
  margin-left: -5px;
  margin-right: -5px;
  margin-top: -5px;
}

.vjs-control .vjs-button {
  width: 100%;
  height: 100%;
  margin-top: 1px;
}

.vjs-has-started .vjs-big-play-button{
  display: none;
}

.vjs-waiting .vjs-loading-spinner {
  /* display: none; */
  animation: vjs-spinner-show 0s linear 0.3s forwards;
}
.vjs-time-divider {
  display: none;
  line-height: 3em;
}

.video-js.vjs-layout-small .vjs-time-divider{
  display: none;
}

.video-js .vjs-time-control {
  flex: none;
  font-size: 1em;
  line-height: 3em;
  min-width: 2em;
  width: auto;
  padding-left: 1em;
  padding-right: 1em;
}

.video-js.vjs-layout-small .vjs-duration{
  display: block;
}

.video-js.vjs-layout-small .vjs-remaining-time{
  display: block;
}

.skip-button {
  background-color: #00000000;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  margin: 0 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  height: 25%;
  margin-left: 30px;
  margin-top: 6rem;
  /* --bs-btn-active-bg: #0a3bca00;
  --bs-btn-border-color: #0d6efd00;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem #ca230a00; */
}


.fullscreen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  z-index: 9999; /* Ensure overlay is on top of everything */
}

.overlay-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}