p{
    color: #FFFFFFCC;
    font-weight: 300;
    text-align: justify;
}

li{
    color: #FFFFFFCC;
    font-weight: 300;
    text-align: justify;
}