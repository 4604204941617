.dropdown-item {
  color: white;
  --bs-dropdown-link-active-color: "green";
}

.dropdown-item:hover {
  background-color: #dddddd; /* Grey color on hover */
}

.btn-secondary {
  --bs-btn-color: #007cc2;
  --bs-btn-hover-color: #3d7bf8;
}
