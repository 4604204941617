/* .centered-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 20px; 
}

.custom-container {
  padding: 20px;
  width: 90%;
  max-width: 900px; 
  background-color: black;
}

.custom-row {
  padding-left: 10px;
}

.custom-col {
  margin-top: 20px;
  padding-bottom: 20px;
  border-right: 1px solid #ffffff; 
}

.custom-muted {
  padding-bottom: 10px;
  font-size: 0.75rem;
  color: #777;
}

.custom-heading {
  margin-top: 20px;
  color: white;
}

.custom-button-div {
  padding-top: 20px;
  text-align: center;
  margin-bottom: 10px;
}


@media (max-width: 576px) {
  .custom-container {
    padding: 10px;
  }
  
  .custom-row {
    padding-left: 0;
    padding-right: 0;
  }
} */

.centered-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%  !important;
  /* height: 100vh; */
  padding: 20px; /* Space around the container */
}

.custom-container-forgot{
  padding: 30px;
  width: 90%;
  max-width: 900px; /* Maximum width for large screens */
  background-color: #2c2c2e33;
  border-radius: 6px;
}

.custom-container {
  padding: 30px;
  width: 90%;
  max-width: 900px; /* Maximum width for large screens */
  background-color: #2c2c2e33;
  border-radius: 6px;
}

.custom-row {
  padding-left: 10px;
}

@media (max-width: 768px) {
  .no-border {
    border: none !important;
  }
}

.custom-col-login {
  border-right: 1px solid #ffffff; /* Using border-md-end class in Bootstrap 5 */
}

.custom-muted {
  padding-bottom: 10px;
  font-size: 0.75rem;
  color: #ffffff;
}

.custom-heading {
  color: white;
}

.custom-button-div-login {
  padding-top: 10px;
  text-align: center;
}

.password-input-container {
  position: relative;
}

.password-toggle {
  background-color: #ffffff;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}